import { getProfileImageUrl } from "../../helpers/images";
import useTranslation from "../../intl/useTranslation";
import { Rating } from "../Common";
import Review from "../review";
import { Status } from "../User";


function DoctorInfo({
  slug,
  title,
  name,
  specialization,
  subSpecialization,
  allClinics,
  rating,
  numReviews,
  appointment,
  visitType,
  topReview,
  isDiscounted,
  showNum,
  isB1G1,
  startingPrice,
  isConsultationFree,
  haveInsurance
}) {
  const { t } = useTranslation();

  let clinicsNames;

  if (allClinics && allClinics.length) {
    let clinicsFullNames = [];
    allClinics.forEach((clinic) => {
      clinicsFullNames.push(clinic.full_name);
    });

    clinicsNames = clinicsFullNames.join(" | ");
  }
  return (
    <>
      <div className="doctor-booking-info-inner-wrapper">
        {appointment && (
          <div className="single-appointment">
            <div className="appointment-header">
              {appointment.datetimeFrom && (
                <div className="date-container">
                  <img className="mr-1" src="/images/appointment-date.svg" alt="No alt" />
                  <span className="date">{appointment.datetimeFrom}</span>
                </div>
              )}
              <Status
                status={appointment.friendly_status.name}
                isDiscounted={isDiscounted}
                statusEn={appointment.friendly_status.name_en}
                videoConsultation={appointment.video_consultation}
                clinicConsultation={appointment.clinic_consultation}
                audioConsultation={appointment.audio_consultation}
                homeConsultation={appointment.home_consultation}
                haveInsurance = {haveInsurance}
              />
            </div>
          </div>
        )}

        <div className="doctor-booking-info-inner desk-only">
          <div className="clip-img img-circle">
            <img className="profile-image" src={getProfileImageUrl(slug, 140, 140, 95)} />
          </div>
          <div className="doctor-booking-details">
            <h2 className="doctor-booking-details-header">
              {title} {name}
            </h2>
            <h3 className="doctor-booking-details-body">
              {specialization} {subSpecialization && subSpecialization !== "-" ? "| " + subSpecialization : ""}
            </h3>
            {allClinics?.map((clinic, index) => (
              <h3 className="doctor-booking-details-clinic" key={`clinic-${index}`}>
                {clinic.full_name ? clinic.full_name : clinic.name}
              </h3>
            ))}

            {rating ? (
              <span className="rating-placeholder" href="#reviews">
                <Rating rating={rating} numReviews={numReviews} showNum={showNum} />
              </span>
            ) : <></>}

            {startingPrice ? (
              <span className="starting-price-placeholder" >
                {startingPrice && !isConsultationFree ? <>{t("Starting Price") + ":"} <span className="starting-price-value">{startingPrice}</span> </>: <span className="starting-price-value">{t("Free Consultation")}</span>} 
              </span>
            ) : <></>}
          </div>
          {topReview && (
            <div>      
              <Review topReview={true} review={topReview} />
            </div>
          )}
        </div>

        <div className="doctor-booking-info-inner mobile-only">
          <div className="clip-img img-circle">
            <img
              className="profile-image"
              src={getProfileImageUrl(slug, 80, 80, 95)}
            />
          </div>
          <div className="doctor-booking-details">
            <h2 className="doctor-booking-details-header">
              {title} {name}
            </h2>
            {/* <h3 className="doctor-booking-details-body">
              {specialization} {subSpecialization && subSpecialization !== "-" ? "| " + subSpecialization : ""}
            </h3>
            {clinicsNames && <h3 className="doctor-booking-details-body">{clinicsNames}</h3>} */}
            {rating ? (
              <span className="rating-placeholder" href="#reviews">
                <Rating rating={rating} numReviews={numReviews} showNum={showNum} />
              </span>
            ) : <></>}

            {startingPrice ? (
              <span className="starting-price-placeholder" >
                {startingPrice && !isConsultationFree ? <>{t("Starting Price") + ":"} <span className="starting-price-value">{startingPrice}</span> </>: <span className="starting-price-value">{t("Free Consultation")}</span>} 
              </span>
            ) : <></>}
            {visitType && (
              <Status
                isDiscount={isDiscounted}
                isB1G1={isB1G1}
                videoConsultation={visitType == "video"}
                clinicConsultation={visitType == "clinic"}
                haveInsurance = {haveInsurance}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default DoctorInfo;
