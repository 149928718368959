import SubmitBookingModal from "@/components/SubmitBookingModal";
import { Mixpanel } from "@/utils";
import { getDoctorBookingType } from "@/utils/booking";
import { getRoute } from "@/utils/url";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { getDoctorThumbnail } from "../../helpers/image-helper";
import useTranslation from "../../intl/useTranslation";

const ButtonGroup = ({ doctor, nextAvailability = null, mixPanelTitle = "", showFeatured = false }) => {
  const { locale, asPath } = useRouter();
  const { t } = useTranslation();
  const { client_side_country } = useSelector(({ InitialDataS }) => InitialDataS);
  const initData = useSelector(({ InitialData }) => InitialData.initData);
  const pageName = asPath.includes("/clinic/")
    ? "Clinic Profile"
    : asPath.includes("/insurance/")
    ? "Insurance Profile"
    : "Doctor Listing";

  const handleBookNow = () => {
    const bookNowProps = {
      "Doctor Name": doctor.name_en,
      "Speciality Name": doctor.specialization,
      "Page Type": pageName,
      Locale: client_side_country || "qatar",
    };

    // we have 4 different events here based on if the user is logged in and if the doctor offers more than one visit type (shows booking modal or not);
    const isLoggedIn = initData.user_email ? "logged in" : "not logged in";
    const isModal = doctor.does_video ? "modal" : "no modal";
    const eventName = `Book Now Button | Clicked (${isLoggedIn}, ${isModal}) | Button`;

    return Mixpanel.track(eventName, bookNowProps);
  };

  const handleViewProfile = () => {
    const viewProfileProps = {
      "Doctor Name": doctor.name_en,
      "Speciality Name": doctor.specialization,
      "Page Type": pageName,
      Locale: client_side_country || "qatar",
    };

    const eventName = `Doctor Card | View Profile Clicked | Button`;

    return Mixpanel.track(eventName, viewProfileProps);
  };

  const getPrice = () => {
    const freeConsultation = doctor.visit_types.reduce((prev, current) => current.free === true, false);
    if (freeConsultation) return t("Free Consultation");
    // regex captures all the letters until the first number, when executed on a string, will result a 3 item list
    const regex = /^(\D*\D)(.*)$/;
    const price = regex.exec(doctor.price_lower_bound?.split(" from ")[1]);
    const priceText = `${price[1]} ${price[2]}`;
    return priceText;
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="doctor-card-button-group-inner" style={{ flexGrow: "1" }}>
        <Link href={getRoute(client_side_country, `/${doctor.slug}`)} locale={locale}>
          <a
            // removed trackViewProfile from here
            onClick={(event) => {
              event.stopPropagation();
            }}
            title={`${doctor.title} ${doctor.name} ${doctor.specialization}`}
          >
            <button
              className={`listings-view-cta button-default-md doctor-card-button-group-btn-view-profile  ${
                !doctor.bookable ? "doctor-card-view-profile-btn" : ""
              }`}
              onClick={handleViewProfile}
            >
              <i className="meddy-profile middle-icon desk-only" aria-hidden="true"></i>
              {" " + t("View Profile") + " "}
            </button>
          </a>
        </Link>
      </div>

      {doctor.bookable && (
        <div
          className="doctor-card-button-group-inner"
          style={{ flexGrow: "2" }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
          title={`${t("Book appointment with")} ${doctor.title} ${doctor.name} ${doctor.specialization}`}
        >
          {nextAvailability?.first_available ? (
            // todo: add the conditional render here
            <SubmitBookingModal
              slug={`/bookings/submit_booking/doctor/${doctor.slug}`}
              imageLink={getDoctorThumbnail(doctor.slug, 70, 70, 95)}
              doctorName={doctor.name}
              rating={doctor.rating}
              numReviews={doctor.num_reviews}
              startingPrice={getPrice()}
              videoVisit={doctor.does_video}
              specialization={doctor.specialization}
              bookingTypes={doctor.visit_types}
              insurance={Boolean(doctor.all_insurances?.length > 0)}
              doctorData={doctor}
              bookingCategory={getDoctorBookingType(doctor)}
            >
              <button
                className="button-default-md listings-next-available doctor-card-button-group-btn-instant doctor-card-button-group-btn-book"
                onClick={handleBookNow}
              >
                <div style={{ lineHeight: "20px" }}>
                  <span style={{ display: "inline" }} className="lightening-emoji">
                    ⚡
                  </span>
                  <span style={{ fontSize: "16px", fontWeight: "700" }}>{t("Book Now")}</span>
                </div>
                <div style={{ lineHeight: "13px" }}>
                  <span style={{ fontSize: "11px" }}>
                    {t("Available")}
                    &nbsp;
                    {nextAvailability?.first_available.charAt(0).toLowerCase() +
                      nextAvailability?.first_available.slice(1)}
                  </span>
                </div>
              </button>
            </SubmitBookingModal>
          ) : (
            <SubmitBookingModal
              slug={`/bookings/submit_booking/doctor/${doctor.slug}`}
              imageLink={getDoctorThumbnail(doctor.slug, 70, 70, 95)}
              doctorName={doctor.name}
              rating={doctor.rating}
              numReviews={doctor.num_reviews}
              startingPrice={getPrice()}
              videoVisit={doctor.does_video}
              specialization={doctor.specialization}
              bookingTypes={doctor.visit_types}
              insurance={Boolean(doctor.all_insurances?.length > 0)}
              doctorData={doctor}
              bookingCategory={getDoctorBookingType(doctor)}
            >
              <button
                className="listings-book-now button-default-md doctor-card-button-group-btn doctor-card-button-group-btn-book"
                onClick={handleBookNow}
              >
                <i className="meddy-calendar middle-icon doctor-listing-calender" aria-hidden="true"></i>
                {" " + t("Book Now") + " "}
              </button>
            </SubmitBookingModal>
          )}
        </div>
      )}
    </div>
  );
};

export default ButtonGroup;
