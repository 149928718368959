import Rating from "@/components/rating";
import { Mixpanel } from "@/utils";
import { getRoute } from "@/utils/url";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { localize } from "utils/url";
import useTranslation from "../../intl/useTranslation";
import ModalContainer from "./ModalContainer";
import {
  ArrowIcon,
  ClinicIcon,
  CloseIcon,
  DescriptionText,
  DoctorInfoSection,
  DoctorName,
  Image,
  ImageContainer,
  InsuranceAvailability,
  Modal,
  ModalClose,
  ModalHeader,
  ModalTitle,
  NameAndRating,
  PriceText,
  StyledAnchor,
  StyledDoctorWrapperLink,
  VideoIcon,
  VisitInfoContainer,
  VisitType,
  VisitTypeContainer,
  VisitTypeTextWrapper,
} from "./SubmitBookingModal.styles";

const SubmitBookingModal = ({
  imageLink,
  doctorName,
  rating,
  numReviews,
  startingPrice,
  insurance,
  slug,
  videoVisit,
  specialization,
  children,
  isDoctorProfile,
  isCallSupportActive,
  bookingTypes,
  doctorData,
  bookingCategory,
}) => {
  const { t } = useTranslation();
  const { locale, route } = useRouter();
  const { client_side_country } = useSelector(({ InitialDataS }) => InitialDataS);
  // adds mixpanel tracking for clicking modal links
  const handleSelectVisit = (visitType) => {
    Mixpanel.track(`Booking Menu Modal | ${visitType} Visit Selected | Button`, {
      // careful in this page: DoctorName is an h3 element, doctorName is a string. Maybe a better name should be used for the element
      "Doctor Name": doctorData.name_en,
      "Visit Type": visitType.charAt(0).toUpperCase() + visitType.slice(1),
      "Speciality Name": specialization
        ? typeof specialization === "object"
          ? specialization?.name_en
          : specialization
        : undefined,
    });
  };

  const getOffer = (visitType) => {
    return bookingTypes?.find((el) => {
      return el.visit_type === visitType;
    });
  };

  const getPriceSection = (visitType) => {
    return (
      <>
        {!getOffer(visitType)?.free && getOffer(visitType)?.starting_price && (
          <PriceText>
            {t("Starting Price")}: <b>{getOffer(visitType)?.starting_price}</b>
          </PriceText>
        )}
        {getOffer(visitType)?.free && getOffer(visitType)?.starting_price && (
          <PriceText>
            <b>{t("Free Consultation")}</b>
          </PriceText>
        )}
      </>
    );
  };

  const Wrapper = ({ children }) => {
    if (route === "/[doctor_name]") {
      return children;
    } else {
      return (
        <div>
          <Link href={`/${localize(`${doctorData?.slug}`, client_side_country, locale)}`}>
            <StyledDoctorWrapperLink>{children}</StyledDoctorWrapperLink>
          </Link>
        </div>
      );
    }
  };
  // render button that opens a modal only if the doctor supports home and video visits, otherwise just render the button that takes you to the booking page.
  if (!videoVisit)
    return (
      <Link
        locale={locale}
        href={getRoute(
          client_side_country,
          `${slug}/?visitType=clinic${bookingCategory ? `&bookingType=${bookingCategory}` : ""}`
        )}
      >
        <StyledAnchor>{children}</StyledAnchor>
      </Link>
    );

  // if the doctor supports home and video visits, render a button that opens a modal for booking.
  return (
    <ModalContainer
      data-cy="submit-booking-modal"
      doctorName={doctorName}
      mixpanelDoctorName={doctorData.name_en}
      specialization={specialization}
      render={({ close }) => (
        <Modal>
          <ModalHeader>
            <ModalTitle>{t("Choose Consultation Type")}</ModalTitle>
            <ModalClose data-cy="close-booking-modal" onClick={close}>
              <CloseIcon data-cy="close-booking-modal" />
            </ModalClose>
          </ModalHeader>
          <Wrapper>
            <DoctorInfoSection>
              <ImageContainer>
                <Image src={imageLink} width={70} height={70} />
              </ImageContainer>
              <NameAndRating>
                <DoctorName>{doctorName}</DoctorName>
                <Rating numReviews={numReviews} rating={rating} showNum={true} />
              </NameAndRating>
            </DoctorInfoSection>
          </Wrapper>
          {/* todo: add the form page links here. */}
          <Link
            href={getRoute(
              client_side_country,
              `${slug}/?visitType=clinic${bookingCategory ? `&bookingType=${bookingCategory}` : ""}`
            )}
            local={locale}
          >
            <StyledAnchor onClick={() => handleSelectVisit("Clinic")}>
              <VisitTypeContainer>
                <VisitInfoContainer>
                  <VisitTypeTextWrapper>
                    <ClinicIcon />
                    <VisitTypeTextWrapper>
                      <VisitType>{t("Clinic Visit")}</VisitType>
                      {insurance && <InsuranceAvailability>{t("insurance available")}</InsuranceAvailability>}
                    </VisitTypeTextWrapper>
                  </VisitTypeTextWrapper>
                  <DescriptionText>{t("Visit the doctor at their clinic")}</DescriptionText>
                  {getPriceSection("clinic")}
                </VisitInfoContainer>
                <ArrowIcon rtl={locale === "ar"} />
              </VisitTypeContainer>
            </StyledAnchor>
          </Link>
          <Link
            href={getRoute(
              client_side_country,
              `${slug}/?visitType=video${bookingCategory ? `&bookingType=${bookingCategory}` : ""}`
            )}
            local={locale}
          >
            <StyledAnchor onClick={() => handleSelectVisit("Video")}>
              <VisitTypeContainer>
                <VisitInfoContainer>
                  <VisitTypeTextWrapper>
                    <VideoIcon />
                    <VisitType>{t("Video Visit")}</VisitType>
                  </VisitTypeTextWrapper>
                  <DescriptionText>{t("Visit the doctor via video consultation.")}</DescriptionText>
                  {getPriceSection("video")}
                </VisitInfoContainer>
                <ArrowIcon rtl={locale === "ar"} />
              </VisitTypeContainer>
            </StyledAnchor>
          </Link>
        </Modal>
      )}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </ModalContainer>
  );
};

export default SubmitBookingModal;
