/**
 * this component is used to display the modal container for the submit booking modal
 * for reference on this example: https://codesandbox.io/s/wandering-dream-qwe6dj?file=/src/App.tsx:2049-2436
 */
import { Mixpanel } from "@/utils";
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useRole,
} from "@floating-ui/react-dom-interactions";
import { cloneElement, useState } from "react";
import { useMediaQuery } from "react-responsive";

const ModalContainer = ({
  render,
  open: passedOpen = false,
  children,
  doctorName,
  specialization,
  mixpanelDoctorName,
}) => {
  const [open, setOpen] = useState(passedOpen);
  const isMobile = useMediaQuery({
    maxWidth: 768,
  });

  // adds a mixpanel event to the onOpenChange function in the usefloating hook
  // this handle the close event for clicking outside the modal.
  // the close button has its own different event handler. (handleCloseModal)
  const handleModalOpenState = () => {
    // reverse the openstate and change the mixpanel event name accordingly.
    setOpen(!open);
    const setModalState = open ? "Closed" : "Open";
    return Mixpanel.track(`Booking Menu Modal | ${setModalState} | Event`, {
      "Doctor Name": mixpanelDoctorName,
      "Speciality Name": specialization,
    });
  };

  // adds a mixpanel event to the click on the close button
  const handleCloseModal = () => {
    setOpen(false);
    return Mixpanel.track(`Booking Menu Modal | Closed | Event`, {
      "Doctor Name": mixpanelDoctorName,
      "Speciality Name": specialization,
    });
  };

  const { x, y, reference, floating, context } = useFloating({
    open,
    onOpenChange: handleModalOpenState,
  });

  const id = useId();
  const labelId = `${id}-label`;
  const descriptionId = `${id}-description`;

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, {
      bubbles: false,
      outsidePointerDown: !isMobile,
    }),
  ]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {cloneElement(
        children,
        getReferenceProps({
          ref: reference,
          ...children.props,
        })
      )}
      <FloatingPortal>
        {open && (
          // todo: wrap it in a styled component instead of having inline style for better readability.
          <FloatingOverlay
            // lockScroll
            style={{
              display: "grid",
              placeItems: `${isMobile ? "end" : "center"} center`,
              background: "rgba(25, 25, 25, 0.8)",
              zIndex: 15,
              height: "100vh",
            }}
          >
            <FloatingFocusManager context={context} preventTabbing={true} returnFocus={false}>
              <>
                <div
                  {...getFloatingProps({
                    ref: floating,
                    className: "Dialog",
                    "aria-labelledby": labelId,
                    "aria-describedby": descriptionId,
                    style: {
                      ...(isMobile
                        ? {
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                          }
                        : {
                            top: y ?? 0,
                            left: x ?? 0,
                          }),
                    },
                  })}
                >
                  {render({
                    close: handleCloseModal,
                    labelId,
                    descriptionId,
                  })}
                </div>
              </>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </div>
  );
};

export default ModalContainer;
