import { Mixpanel } from "../../utils";

export const doctorCardTracker = (mixPanelTitle, doctor, showFeatured, Locale) => {
  const trackBookNow = (platform, event) => {
    Mixpanel.track(
      "Doctor Card | Book Now Clicked | Button",
      {
        "Doctor Name": doctor.name_en,
        "Speciality Name": doctor.specialization,
        "Page Type": mixPanelTitle,
        Locale,
        // "Doctor Name": doctor.name_en,
        // "Clinic Name": doctor.clinic_name_en,
      },
      { send_immediately: true }
    );
    event?.stopPropagation();
  };
  const trackCard = () => {
    Mixpanel.track("Doctor Card | Card Clicked | Card", {
      "Doctor Name": doctor.name_en,
      "Speciality Name": doctor.specialization,
      "Page Type": mixPanelTitle,
      Locale,
      // "Clinic Name": doctor.clinic_name,
    });
  };

  const trackViewProfile = (platform, event) => {
    Mixpanel.track(
      "Doctor Card | View Profile Clicked | Button",
      {
        "Doctor Name": doctor.name_en,
        "Speciality Name": doctor.specialization,
        "Page Type": mixPanelTitle,
        Locale,
        // "Doctor Name": doctor.name_en,
        // "Clinic Name": doctor.clinics_name_en,
      },
      { send_immediately: true }
    );

    event?.stopPropagation();
  };

  const trackClinicClicked = (clinicName, event) => {
    Mixpanel.track(
      "Doctor Card | Clinic Name Clicked | Link",
      {
        "Doctor Name": doctor.name_en,
        "Speciality Name": doctor.specialization,
        "Page Type": mixPanelTitle,
        // "Doctor Name": doctor.name_en,
        "Clinic Name": clinicName,
        Locale,
      },
      { send_immediately: true }
    );
    event?.stopPropagation();
  };
  return {
    trackBookNow,
    trackCard,
    trackClinicClicked,
    trackViewProfile,
  };
};
