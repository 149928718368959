import Image from "next/image";
import Link from "next/link";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDoctorFirstAvailable } from "../../data/actions/doctorProfile";
import useTranslation from "../../intl/useTranslation";
import { doctorCardTracker } from "../../services/tracker/doctorCardTracker";
import { getCompleteUrl, getRoute, isMobile } from "../../utils";
import { Rating } from "../Common";
import ButtonGroup from "./ButtonGroup";
// this is just as a simulation for admin view

const DoctorCard = memo(({ doctor, showFeatured, mixPanelTitle, loading, showAdminView }) => {
  const { t } = useTranslation();
  const { locale, language_code, client_side_country } = useSelector(({ InitialDataS }) => InitialDataS);
  const { canonical, meta_desc } = useSelector(({ SeoData }) => SeoData);
  const [mobile, setMobile] = useState();
  const [adminView, setAdminView] = useState(showAdminView);
  const [nextAvailability, setNextAvailability] = useState(doctor.next_available);

  // extract the clinic visit object to be used for displaying starting price in the doctor card.
  const clinicVisitInfo = doctor.visit_types.reduce((prev, next) => (next.visit_type === "clinic" ? next : prev), {});

  useEffect(async () => {
    if (doctor.next_available.first_available !== null) {
      const data = await getDoctorFirstAvailable(doctor.id, client_side_country, language_code);
      if (data) {
        setNextAvailability(data.data);
      }
    }
  }, []);
  useEffect(() => {
    setMobile(isMobile());
  }, []);

  useEffect(() => {
    setAdminView(showAdminView);
  }, [showAdminView]);

  const checkVisitType = (visitType) => {
    return doctor.visit_types?.some((type) => {
      return type.visit_type == visitType;
    });
  };

  let lowerPrice = doctor?.price_lower_bound ? doctor?.price_lower_bound.split(" ") : [];
  lowerPrice = lowerPrice.length !== 0 && lowerPrice[lowerPrice.length - 1];

  const address = doctor.all_clinic_locations?.length ? doctor.all_clinic_locations[0].address : null;
  const slug = loading ? "" : doctor.slug;

  const Language = () => {
    return (
      <p
        style={{
          display: "flex",
          fontWeight: "400",
          fontSize: "14px",
          color: "#A2A3A6",
          marginBottom: "5px",
          marginTop: "5px",
        }}
      >
        <i className="meddy-speech-bubble middle-icon" style={{ marginTop: "3px" }}></i>
        <div>
          {doctor?.languages.map((language, index) => (
            <shy key={index}>
              {" "}
              <span style={{ margin: "0px 2px" }} key={index}>
                {language?.name}
                {index != doctor.languages.length - 1 && <span>,</span>}
              </span>
            </shy>
          ))}
        </div>
      </p>
    );
  };

  const NameGroup = () => {
    return (
      <div id="name-group" style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
          <div className="img-circle lazy-img-placeholder doctor-card-name-group-image-wrapper">
            <Image
              alt={`${doctor?.title} ${doctor?.name}`}
              className="doctor-card-pic"
              loading="lazy"
              width="130"
              height="130"
              // className="listings-card-pic"
              src={getCompleteUrl(`/profilepic/${slug}/100/100/95`)}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "0px 10px" }}>
          {doctor?.rating > 0 && (
            <div className="rating-placeholder">
              <Rating rating={doctor?.rating} numReviews={doctor?.num_reviews} showNum={false} />
            </div>
          )}
          <h3 style={{ fontSize: "16px", color: "#282828", fontWeight: "700", margin: "5px 0px 3px 0px" }}>
            {doctor?.title} {doctor?.name}
          </h3>
          <p style={{ fontWeight: "400", color: "#555555", fontSize: "14px", marginBottom: "0px" }}>
            {doctor?.specialization}
            {doctor?.sub_specialization && <span> | {doctor?.sub_specialization}</span>}
          </p>
          {/* {!mobile && <Language></Language>} */}
        </div>
      </div>
    );
  };

  const PriceGroup = () => {
    return (
      <div id="price-group" className="doctor-card-price-group">
        {/* <Language></Language> */}
        <p className="listings-card-subheader doctor-card-price-group-item">
          <i className="meddy-speech-bubble middle-icon" style={{ marginTop: "3px" }}></i>
          <div>
            {doctor?.languages.map((language, index) => (
              <shy>
                {" "}
                <span key={index}>
                  {language?.name}
                  {index != doctor.languages.length - 1 && <span>,</span>}
                </span>
              </shy>
            ))}
          </div>
        </p>
        <p className="listings-card-subheader doctor-card-price-group-item" style={{ display: "flex" }}>
          <i className="meddy-clinic middle-icon" style={{ marginTop: "2px" }}></i>
          <div>
            {doctor?.all_clinics.map((clinic, index) => {
              return (
                <div key={index}>
                  {
                    <span>
                      <Link href={getRoute(client_side_country, `/clinic/${clinic.slug_en}`)}>
                        <a
                          onClick={(event) =>
                            doctorCardTracker(
                              mixPanelTitle,
                              doctor,
                              showFeatured,
                              client_side_country || "qatar"
                            ).trackClinicClicked(clinic.name_en, event)
                          }
                          style={{ fontSize: "14px" }}
                          className="listings-subheader-link"
                          title={`${doctor.title} ${doctor.name} ${clinic.name}`}
                        >
                          {clinic.name} {clinic.branch || ""}{" "}
                          {clinic?.area?.name && <span style={{ color: "#A2A3A6" }}>({clinic.area.name})</span>}
                        </a>
                      </Link>
                    </span>
                  }
                  {clinic?.other_branches?.length && <span> , </span>}
                  {clinic.other_branches && (
                    <span>
                      {/* {clinic?.name} (
                    <Link href={getRoute(client_side_country, `/clinic/${clinic.slug_en}`)}>
                      <a
                        onClick={(event) =>
                          doctorCardTracker(mixPanelTitle, doctor, showFeatured).trackClinicClicked(
                            clinic.slug_en,
                            event
                          )
                        }
                        title={`${doctor.title} ${doctor.name} ${clinic.branch}`}
                        style={{fontSize:"14px"}}

                        className="listings-subheader-link"
                      >
                        {clinic.branch}
                      </a>
                    </Link>
                    , */}
                      {clinic.other_branches.map((branch, Index) => (
                        <span key={Index}>
                          <Link href={getRoute(client_side_country, `/clinic/${branch.slug_en}`)}>
                            <a
                              onClick={(event) =>
                                doctorCardTracker(
                                  mixPanelTitle,
                                  doctor,
                                  showFeatured,
                                  client_side_country || "qatar"
                                ).trackClinicClicked(branch.name_en, event)
                              }
                              className="listings-subheader-link"
                              style={{ fontSize: "14px" }}
                              title={`${doctor.title} ${doctor.name} ${branch.name}`}
                            >
                              {branch.name} {branch.branch_name || ""}{" "}
                              {branch?.area?.name && <span style={{ color: "#A2A3A6" }}>({branch.area.name})</span>}
                            </a>
                          </Link>
                          {Index !== clinic.other_branches.length - 1 && <span>, </span>}
                        </span>
                      ))}
                    </span>
                  )}
                  {index !== doctor.all_clinics.length - 1 && <span> , </span>}
                </div>
              );
            })}
          </div>
        </p>
        {doctor.price_lower_bound &&
          (clinicVisitInfo.free ? (
            <p className="listings-card-subheader price-limit-div doctor-card-price-group-item">
              <i className="meddy-price-tag middle-icon"></i>
              {t("Price starting from")} {t("Free Consultation")}
            </p>
          ) : clinicVisitInfo.starting_price ? (
            <p className="listings-card-subheader price-limit-div doctor-card-price-group-item">
              <i className="meddy-price-tag middle-icon"></i>
              {t("Price starting from")} {clinicVisitInfo.starting_price}
            </p>
          ) : null)}
      </div>
    );
  };

  // extract this to an external file.

  return (
    <>
      <Link href={getRoute(client_side_country, `/${doctor?.slug}`)}>
        <a
          itemType="http://schema.org/Physician"
          className="listings-card doctor-card-wrapper medium-dropshadow"
          title={`${t("Best")} ${
            doctor.specialization_name_meta ? doctor.specialization_name_meta : doctor.specialization
          } ${t("in")} ${doctor?.all_clinics[0]?.area?.city}`}
          style={{ paddingBottom: doctor?.does_video ? "10px" : "15px" }}
          onClick={(e) => {
            e.target.className !== "listings-view-cta button-default-md" &&
              e.target.className !== "listings-book-now button-default-md" &&
              e.target.className !== "listings-subheader-link" &&
              doctorCardTracker(mixPanelTitle, doctor, showFeatured, client_side_country || "qatar").trackCard();
          }}
        >
          <>
            <meta itemProp="name" content={`${doctor?.title} ${doctor?.name}`} />
            <meta itemProp="image" content={getCompleteUrl(`/profilepic/${doctor?.slug}/300/300/95`)} />
            <meta itemProp="medicalSpecialty" content={doctor?.specialization?.name} />
            {address && <meta itemProp="address" content={`${doctor?.all_clinics[0]?.area?.name} ${address}`} />}
            {!address && <meta itemProp="address" content={doctor?.all_clinics[0]?.area.name} />}
            <meta itemProp="telephone" content={doctor?.all_clinics[0]?.contact_no} />
            <meta itemProp="priceRange" content={doctor?.price_lower_bound} />
            <meta itemProp="geo" content={doctor?.all_clinics[0]?.geolocation} />
            <meta
              itemProp="hasMap"
              content={`https://www.google.com/maps/place/${
                doctor?.all_clinics[0]?.geolocation ? doctor?.all_clinics[0]?.geolocation : ""
              }`}
            />
            <meta itemProp="url" content={canonical} />
            <meta itemProp="description" content={meta_desc} />
          </>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <div className="special-identifiers" style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="status-container">
                  {doctor?.does_video && (
                    <div className="consultation-status video">
                      <img src="/images/icon-video-white.svg" alt="Video Consultation" />
                      {t("Video Visit")}
                    </div>
                  )}{" "}
                  {showFeatured && doctor?.featured && <div className="featured-identifier">{t("Featured")}</div>}{" "}
                  {doctor?.offer_list.length > 0 && (
                    <div className="special-offers-identifier">{t("Special Offers")}</div>
                  )}
                  {doctor?.popular && <div className="popular-doctor-identifier">{t("Popular")}</div>}{" "}
                  {doctor?.does_audio && (
                    <div className="visit audio-visit">
                      <img src="/images/icon-hangup-white.svg" alt="" />
                      {t("Audio Visit")}
                    </div>
                  )}
                  {doctor?.does_home && (
                    <div className="visit home-visit">
                      <img src="/images/icon-home-white.svg" alt="" />
                      {t("Home Visit")}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="doctor-card-name-price-group-container">
              <div className="doctor-card-name-group-wrapper">
                <NameGroup></NameGroup>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  flexBasis: 0,
                  flexGrow: 1,
                  marginRight: "10px",
                }}
              >
                <PriceGroup></PriceGroup>
              </div>
            </div>

            {adminView && (
              <div className="row">
                <div className="col-xs-12">
                  <p className="listings-card-subheader">Bookings this month: {doctor.bookings_this_month}</p>
                  {doctor.consultation_prices && (
                    <p className="listings-card-subheader" style={{ whiteSpace: "pre-line" }}>
                      Consultation prices:&#10; {doctor.consultation_prices}%
                    </p>
                  )}
                  <p className="listings-card-subheader">Visiting: {doctor.visiting}%</p>
                  {doctor.visiting_notes && (
                    <p className="listings-card-subheader">Visiting Notes: {doctor.visiting_notes}%</p>
                  )}
                  {doctor.ranking && <p className="listings-card-subheader">Ranking: {doctor.ranking}%</p>}
                  {doctor.page_views_ratio && (
                    <p className="listings-card-subheader">Page Views: {doctor.page_views_ratio}%</p>
                  )}
                  {doctor.reviews_ratio && <p className="listings-card-subheader">Reviews: {doctor.reviews_ratio}%</p>}
                  {doctor.bookings_ratio && (
                    <p className="listings-card-subheader">Bookings: {doctor.bookings_ratio}%</p>
                  )}
                  {doctor.content_ratio && <p className="listings-card-subheader">Content: {doctor.content_ratio}%</p>}
                </div>
              </div>
            )}

            <div className="doctor-card-button-group-wrapper" onClick={(e) => e.stopPropagation()}>
              {doctor && (
                <ButtonGroup
                  doctor={doctor}
                  nextAvailability={nextAvailability}
                  mixPanelTitle={mixPanelTitle}
                  showFeatured={showFeatured}
                />
              )}
            </div>
            {checkVisitType("video") && checkVisitType("clinic") && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "12px",
                  fontWeight: "600",
                  marginTop: "10px",
                  color: "#555555",
                }}
              >
                <span>{t("Offers clinic & video visits")}</span>
              </div>
            )}

            {checkVisitType("video") && !checkVisitType("clinic") && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "12px",
                  fontWeight: "600",
                  marginTop: "10px",
                  color: "#555555",
                }}
              >
                <span>{t("Offers video visits only")}</span>
              </div>
            )}
          </div>
        </a>
      </Link>
    </>
  );
});

export default DoctorCard;
