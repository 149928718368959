import { DoctorProfile } from "types/Doctors";

export const getDoctorBookingType = (doctorProfile: DoctorProfile["profile"]) => {
  // This because Data inconsistency, next_available | nextAvailable
  const mapper = { next_available: (doctorProfile as any).nextAvailable, ...doctorProfile };

  if (mapper.next_available.walkin) return "walkin";
  if (!!mapper.next_available.first_available) return "instant";
  return "regular";
};
