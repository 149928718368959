/**
 * current styles override most of the SC margins...etc. Thus there will be a lot of !important flags for now.
 */
import clinic from '@/public/next-images/heliumHealthIcons/clinic.svg';
import video from '@/public/next-images/heliumHealthIcons/video.svg';
import CrossIcon from '@/public/next-images/navigationIcons/cross.svg';
import RightArrowBlueBackground from '@/public/next-images/navigationIcons/right-arrow-blue-background.svg';
import NextImage from 'next/image';
import Link from 'next/link';
import styled, { keyframes } from 'styled-components';

export const slideUp = keyframes`
  from {
    transform: translateY(100%);
  } to {
    transform: translateY(0);
  }
`;

export const Modal = styled.div`
  width: 500px;
  height: 426px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 728px) {
    transform: translateY(100%);
    animation: ${slideUp} 0.2s ease-in-out forwards;
    width: 100vw;
    height: 430px;
  }
`;
export const StyledDoctorWrapperLink = styled.a`
  text-decoration: none !important;
  cursor: pointer;
  display: inline-flex;
  &:hover {
    text-decoration: none !important;
  }
`;
// todo: check if maybe this should be a header?
export const ModalHeader = styled.div`
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e7e9ef;
  border-radius: 5px 5px 0 0;
`;

export const ModalTitle = styled.h2`
  font-family: 'Mulish';
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  color: #282828;
  margin: 0 0 0 15px !important;
`;

export const ModalClose = styled.button`
  background: none;
  border: none;
  width: 48px !important;
  height: 48px !important;
  margin: 0 15px 0 0 !important;
  padding: 0;
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  padding-right: 4px;
`;

export const CloseIcon = styled(CrossIcon)`
  width: 13px !important;
  height: 13px !important;
`;

// todo: review the semantics for this.
export const DoctorInfoSection = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  margin: 15px 0;
`;

export const ImageContainer = styled.div`
  border-radius: 50%;
  border: 2px #fff solid;
  width: 70px;
  height: 70px;
  box-shadow: 0px 4px 8px rgba(85, 85, 85, 0.25);
  margin: 0 20px;
`;

export const Image = styled(NextImage)`
  max-width: 70px;
  max-height: 70px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;

export const NameAndRating = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DoctorName = styled.h3`
  font-family: 'Mulish';
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 0 !important;
  color: #282828;
`;

export const VisitTypeContainer = styled.div`
  cursor: pointer;
  width: calc(100% - 40px);
  height: 100px;
  background: #f4f4f9;
  border-radius: 10px;
  margin: 15px 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    text-decoration: none !important;
  }

  @media screen and (max-width: 728px) {
    width: calc(100% - 30px);
  }
`;

export const VisitInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 15px !important;
`;

export const ArrowIcon = styled(RightArrowBlueBackground)`
  width: 30px;
  height: 30px;
  margin: 0 10px;
  transform: ${({ rtl }) => rtl && `rotate(180deg)`};
`;

export const ClinicIcon = styled(clinic)`
  width: 11px;
  height: 18px;
`;

export const VideoIcon = styled(video)`
  width: 21px;
  height: 13px;
`;

export const VisitType = styled.h3`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #282828 !important;
  margin: 0 10px !important;
`;

export const DescriptionText = styled.p`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #a2a3a6 !important;
  margin: 0 !important;
`;

export const PriceText = styled(DescriptionText)`
  color: #2a3390 !important;
`;

export const VisitTypeTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
`;

export const InsuranceAvailability = styled.span`
  background: #48bfc0;
  border-radius: 10.5px;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 18px !important;
  color: #ffffff !important;
  width: 107px;
  height: 19px;
  text-align: center;
`;

export const StyledAnchor = styled.a`
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
`;
