import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getProfileImageUrl } from "../helpers/images";
import useTranslation from "../intl/useTranslation";
import { deleteReviewApi, editReview } from "../services/review";
import { Mixpanel } from "../utils";
import { MeddyModal, Notify } from "./Common";
import ReviewForm from "./Reviews/reviewForm";
import ConfirmationModal from "./confirm";
import { GENDER } from "./discount/discountProfile";

function Review({ review, featured, topReview, showDoctorData }) {
  const [truncated, setTruncated] = useState(true);
  const [showMoreReviews, setShowMoreReviews] = useState(false);
  const [visibleReviewIndex, setVisibleReviewIndex] = useState(3);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editObj, setEditObj] = useState(null);
  const [reviewToDelete, setReviewToDelete] = useState(null);
  const [editErr, setEditErr] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  const [alertData, setAlertData] = useState({ title: "", type: "", message: "" });
  const [showExpansionOption, setShowExpansionOption] = useState(false);

  const { t } = useTranslation();
  const ref = useRef();
  const { client_side_country, language_code } = useSelector(({ InitialDataS }) => InitialDataS);

  // this is just as a simulation to ownUser
  const ownUser = false;

  const confirmEdit = async () => {
    const { sentiment, comment, id: reviewId } = editObj;
    Mixpanel.track(`Doctor Profile - Comment Edit Submitted`);

    if (!editObj.sentiment) {
      setEditErr(true);
      return;
    }

    const data = await editReview(sentiment, comment, reviewId, client_side_country, language_code);
    setOpenEditModal(false);
    setEditObj(null);
    if (data.success) {
      setOpenNotify(true);
      setAlertData({
        title: t("Success!"),
        message: t("Review has been edited successfully!"),
        type: "success",
      });
    }
  };

  const deleteReview = async (review) => {
    const reviewId = review.id;
    Mixpanel.track("Doctor Profile - Comment Delete Submitted");
    const data = await deleteReviewApi(reviewId, client_side_country, language_code);
    if (data.success) {
      setOpenNotify(true);
      setAlertData({
        title: t("Success!"),
        message: t("Review has been deleted successfully!"),
        type: "success",
      });
    }
  };
  const handleEdit = (key, e) => {
    setEditObj({ ...editObj, [key]: e.target.value });
  };

  useEffect(() => {
    if (ref.current) {
      const objHeight = ref.current.clientHeight;
      setShowExpansionOption(objHeight > 56);
    }
  }, [review]);

  const expandReviews = () => {
    setShowMoreReviews(!showMoreReviews);
    if (showMoreReviews) setVisibleReviewIndex(review.reviews.length);
    else setVisibleReviewIndex(3);
  };

  const getReviewerName = (name) => {
    if (/^([+]\d{2,})/.test(name)) {
      return "+" + name.replace(/.(?=.{3})/g, "*");
    } else {
      return name;
    }
  };

  return (
    <>
      <div
        className={`comment ${featured ? "featured" : ""} ${topReview ? "top-review" : ""}`}
        itemScope
        itemProp="reviews"
        itemType="http://schema.org/Review"
      >
        {topReview && <img src="/images/icon-quotation.svg" alt="Comment Icon" className="icon-quotation" />}
        <div className="header-doctor">
          <div className="comment-header">
            <h5 className="comment-name" itemScope itemProp="author" itemType="http://schema.org/Person">
              <span itemProp="name">{getReviewerName(review.name)}</span>
            </h5>
          </div>
          {showDoctorData && (
            <span className="header-doc-info">
              <span itemProp="name" style={{ fontSize: "11px" }}>
                {review.doctor.title} {review.doctor.name}
              </span>
              <img
                className="review-doc-img"
                src={
                  review.doctor.slug
                    ? getProfileImageUrl(review.doctor.slug, 100, 100, 95)
                    : review.doctor.gender === GENDER.MALE
                    ? "/next-images/default-mobile-male.png"
                    : "/next-images/default-mobile-female.png"
                }
              />
            </span>
          )}
        </div>
        {review.reviews.slice(0, visibleReviewIndex).map((reviewObj, index) => (
          <div className="comment-container" key={`comment${index}`}>
            {reviewObj.sentiment == 3 ? (
              <div className="status-wrapper">
                <span className="status-icon meddy-satisfied"></span> {t("Satisfied")}
              </div>
            ) : reviewObj.sentiment == 2 ? (
              <div className="status-wrapper">
                <span className="status-icon meddy-neutral"></span> {t("Neutral")}
              </div>
            ) : reviewObj.sentiment == 1 ? (
              <div className="status-wrapper">
                <span className="status-icon meddy-unsatisfied"></span> {t("Unsatisfied")}
              </div>
            ) : null}
            <p
              className={`comment-body ${truncated ? "truncated" : ""} ${showExpansionOption ? "allow-expand" : ""}`}
              itemProp="reviewBody"
              ref={ref}
            >
              {" "}
              {reviewObj.comment}
              {showExpansionOption && (
                <a onClick={() => setTruncated(!truncated)} className="expand-text">
                  {truncated ? <span>{t("Show more")}</span> : <span>{t("Show less")}</span>}
                </a>
              )}
            </p>
            <meta itemProp="datePublished" content={reviewObj.isoDate} />
            <span className="date-edit-wrapper">
              {ownUser && (
                <>
                  <a
                    className="edit-button"
                    onClick={() => {
                      setOpenEditModal(true);
                      setEditObj(reviewObj);
                    }}
                  >
                    {t("Edit")}
                  </a>
                  <a className="delete-button desk-only" onClick={() => setReviewToDelete(reviewObj)}>
                    {t("Delete")}
                  </a>
                </>
              )}

              <p className="comment-date">{reviewObj.date}</p>
            </span>
            {reviewObj.replyList.length > 0 && (
              <div>
                <div className="comment-reply-wrapper">
                  {reviewObj.replyList.map((reply, index) => (
                    <div className="comment-reply" key={index}>
                      <p className="reply-name">The manager replied</p>
                      <p className="reply-comment">{reply.replyComment}</p>
                      <p className="reply-date">{reply.date}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
        {review.reviews.length > 3 && (
          <div className="show-reviews-wrapper">
            {!showMoreReviews ? (
              <a type="button" className="show-reviews" onClick={expandReviews}>
                {t("Show more")} <span className="meddy-chevron chevron-down"></span>
              </a>
            ) : (
              <a type="button" className="show-reviews" onClick={expandReviews}>
                {t("Show less")} <span className="meddy-chevron chevron-up"></span>
              </a>
            )}
          </div>
        )}
      </div>
      {openEditModal && ownUser ? (
        <MeddyModal
          confirmationText={t("Save Changes")}
          confirmAction={confirmEdit}
          close={() => {
            setOpenEditModal(false);
            setEditObj(null);
          }}
          customClassName={["review-action-modal", "meddymodalactive"]}
        >
          <div className="meddy-modal-heading">
            <h2>{t("Edit Review")}</h2>
            <span>
              <a className="delete-button mobile-only" onClick={() => setReviewToDelete(editObj)}>
                {t("Delete Review")}
              </a>
              <i
                className="meddy-close cancel-icon-modal"
                onClick={() => {
                  setOpenEditModal(false);
                  setEditObj(null);
                }}
                aria-hidden="true"
              ></i>
            </span>
          </div>
          <div className="meddy-modal-body">
            <ReviewForm review={editObj} handleEdit={handleEdit} error={editErr} />
          </div>
        </MeddyModal>
      ) : null}
      {reviewToDelete ? (
        <ConfirmationModal
          title={t("Delete Review?")}
          body={t("Are you sure you want to delete this review?")}
          closeConfirm={() => setReviewToDelete(null)}
          failure={() => setReviewToDelete(null)}
          success={() => {
            deleteReview(reviewToDelete);
            setReviewToDelete(null);
          }}
        />
      ) : null}
      {/* this component will render only when notify after edit and delete response return */}
      {openNotify && (
        <Notify
          type={alertData.type}
          message={alertData.message}
          title={alertData.title}
          closeNotification={() => setOpenNotify(false)}
        />
      )}
    </>
  );
}

export default Review;
